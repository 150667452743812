import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/cartActions'
import Meta from '../components/Meta'

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id

  const qty = location.search ?  Number(location.search.split("?")[1].split("&")[0].split("=")[1]) : 1
  const discount = location.search ?  location.search.split("?")[1].split("&")[1].split("=")[1] : ""
  
  // const addon = location.search ? decodeURIComponent(location.search.split("?")[1].split("&")[1].split("=")[1]) : "no addon"
  // const addonPrice = location.search ? decodeURIComponent(location.search.split("?")[1].split("&")[2].split("=")[1]) : 0

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty,discount))
    }
  }, [dispatch, productId, qty])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    history.push('/shipping')
  }

  return (
    <div className="pt-32  ">
      <Meta title={"Shopping Cart"} />

      <div className="max-w-screen-lg mx-auto  justify-between">
        <div className="flex-1 px-4">
          <div className="flex items-center ">
            <button onClick={history.goBack}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
            </button>
            
            <h1 className="px-3 text-2xl">Shopping Cart</h1>

          </div>
          {cartItems.length === 0 ? (
            <div className='py-10 text-center'>
              <div>
                Your cart is empty 
              </div>
              <div className='pt-4'>
                <Link className='text-blue-600' to={"/"} >Go back</Link>
              </div>
            </div>
          ) : (
            <div className="py-2" >
              {cartItems.map((item) => (
                <div key={item.product}>
                  <div className="flex my-2 items-center px-5 py-3 justify-between w-full  bg-white shadow-sm rounded-md">
                    <div className="flex ">
                      <img src={`${process.env.REACT_APP_ENV=="prod"? 'https://difatours.aynnaka.app':'http://127.0.0.1:8000'}${item.image}`} alt={item.name} className="w-20 h-20" />
                      <div className="pl-4" >
                        <Link className="text-md " to={`/product/${item.product}`}>{item.name}</Link>
                        <div className="text- opacity-90 " >{item.price} MAD</div>

                        <div className="text-sm font-bold opacity-70" >Discount ({Number(item.discount)*100}%) :  (- {item.price*Number(item.discount)} MAD)</div>
                      </div>
                    </div>
                   
                    <div className="border p-1" >
                      <select
                        className="outline-none w-10 bg-transparent select "
                        value={item.qty}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product, Number(e.target.value),discount)
                          )
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div >
                      <button
                        type='button'
                        variant='light'
                        onClick={() => removeFromCartHandler(item.product)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="px-4 flex justify-end">
              <div >
                <div className="">
                  <h2 className="py-3">
                    
                    Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items
                     : <span className="font-bold">
                       
                       {cartItems
                      .reduce((acc, item) => acc + (item.qty * (parseFloat(item.price) - parseFloat(item.price)*parseFloat(item.discount))) , 0)
                      .toFixed(2)} MAD
                     </span>
                  </h2>
                </div>
                <div>
                  <button
                    type='button'
                    className='p-2 border border-primary text-primary mr-4 '
                  
                    onClick={() => 
                      history.go(-2)}
                  >
                    Continue shopping
                  </button>
                  <button
                    type='button'
                    className={`p-2 border border-primary bg-primary disabled:bg-opacity-40 text-white `}
                    disabled={cartItems.length === 0}
                    onClick={checkoutHandler}
                  >
                    Proceed To Checkout
                  </button>
                </div>
                
              </div>
             
            </div>
      </div>
    </div>
  )
}

export default CartScreen
