import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import { getUserDetails, updateUser } from '../../actions/userActions'
import { USER_UPDATE_RESET } from '../../constants/userConstants'
import SideBar from '../../components/SideBar'

const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET })
      history.push('/admin/userlist')
    } else {
      if (!user.name || user._id !== Number(userId)) {
        dispatch(getUserDetails(userId))
      } else {
        setName(user.name)
        setEmail(user.email)
        setIsAdmin(user.isAdmin)
      }
    }
  }, [dispatch, history, userId, user, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUser({ _id: userId, name, email, isAdmin }))
  }

  return (
    <>
      <div className="admin-screen">
        <div className="flex pt-32">
          
          <SideBar />
        
          <div className="w-full pl-1">
              <h1 className='text-xl font-bold'>Edit User</h1>
              {loadingUpdate && <Loader />}
              {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <form onSubmit={submitHandler}>
                  <div className='border relative my-2'>
                    <label className='absolute opacity-50 top-2 left-2'>Name</label>
                    <input
                      type='name'
                      className='p-2 outline-none w-full bg-transparent pl-32'
                      placeholder='Enter name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>

                  <div className='border relative my-2'>
                    <label className='absolute opacity-50 top-2 left-2'>Email Address</label>
                    <input
                      type='email'
                      className='pl-32 bg-transparent outline-none p-2'
                      placeholder='Enter email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>

                  <div >
                    <label htmlFor="isAdmin">Is Admin</label>
                    <input
                      type='checkbox'
                      id='isAdmin'
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                    ></input>
                  </div>

                  <button className='bg-black my-2 text-white px-4 py-2 ' >
                    Update
                  </button>
                </form>
              )}
          </div>
        </div>
      </div>
 
    
    </>
  )
}

export default UserEditScreen
