import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

function Video({poster,src}) {

    const vidRed = useRef()
    const [isPlayed, setIsPlayed] = useState(false)
    useEffect(() => {
        vidRed.current.play()
    }, [])
    const pauseVideo = () =>{
        setIsPlayed(false)
        vidRed.current.pause()
    }

    const playVideo = () =>{
        setIsPlayed(true)
        vidRed.current.play()
    }
    return (
        <div className='relative'>

            <video ref={vidRed} className="md:w-screen md:h-[90vh] h-[75vh] object-cover" playsinline autoplay muted loop poster={poster} >
                {/* <source src="lump.webm" type="video/webm" /> */}
                <source   src={src} type="video/mp4" /> 
            </video>
            {/* <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2'>
               {!isPlayed ? 
               <div onClick={playVideo} className='p-2 cursor-pointer z-50 bg-primary text-white bg-opacity-90 rounded-full'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
               </div>
               :
               <div onClick={pauseVideo} className='p-2 cursor-pointer z-50 bg-primary text-white bg-opacity-90 rounded-full'>
                    <svg xmlns="http://www.w3.org/2000/svg"  className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                         <path strokeLinecap="round" strokeLinejoin="round" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
               </div>
               }
            </div> */}
        </div>


    )
}

export default Video