import React from 'react'
import SideBar from '../../components/SideBar'

function MessageScreen() {
    
  return (
    <div className="admin-screen">
        <div className="flex ">
            <div className='w-1/5 '>
                <SideBar />
            </div>

            <div className="px-2 md:w-3/5 relative w-full">
                <h1 className='text-xl pb-1 '>Messages </h1>
                <hr />

            </div>
        </div>
    </div>
  )
}

export default MessageScreen