import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import './ProductScreen.css'
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
// const getFormattedPrice = (price) => `$${price.toFixed(2)}`;

function pad2(number) {
   
  return (number < 10 && number !=="00" ? '0' : '') + number

}
const ProductScreen = ({ history, match }) => {

  const [qty, setQty] = useState(1)
  const [ticket, setTicket] = useState(false)
  const [discount, setDiscount] = useState()

  const [priceticket, setPriceticket] = useState(0)

  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [activeImage, setActiveImage] = useState(0)
  const [total, setTotal] = useState(0)
  const dispatch = useDispatch()

  const discounts = [{name:"-4 ans",discount:"0.20"},{name:"4-12 ans",discount:"0.40"}]

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails
  

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate

  useEffect(() => {
    if (successProductReview) {
      setRating(0)
      setComment('')
    }
    if (!product._id || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id))
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
      
    }

  }, [dispatch, match, successProductReview])

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}&discount=${discount}`)
  }



  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    )
  }

  const handleChangeDiscount = (e) => {
    // console.log(e.target.value)
    setDiscount(e.target.value)
    // setTicket(e.target.value)
    if (discount){
      setTotal(parseFloat(product.price) - parseFloat(discount)*parseFloat(product.price) )  
   
      }
    else{
      setTotal( parseFloat(product.price) )
    }
  
  }


  const goBack = () => {
      history.goBack()
  }

  return (
    <div className="pt-32 pb-20 xl:px-0 px-4 max-w-screen-xl mx-auto">
     <button className='my-4 ' onClick={goBack}>
        <div className="flex px-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
            <div className="pl-3">
              Back
            </div>
        </div>
      
         
      </button>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          {/* <Meta title={product.name} /> */}
          <div className="md:flex rounded-md pt-6 gap-3  ">

              <div className='md:w-2/3 ' >
                  <div className=' flex flex-col-reverse items-start justify-center  relative '>
                    <div className='pt-4 flex justify-center flex-wrap items-center '>
                
                        {product?.images && product?.images?.length >0  && product.images?.map(
                          (img,id)=>  <div  style={{backgroundImage:`url(${process.env.REACT_APP_ENV=="prod"? 'https://difatours.aynnaka.app':'http://127.0.0.1:8000'}${img.image})`}} onClick={()=> setActiveImage(id)} 
                                    className={`${activeImage ===id ? " opacity-100":"opacity-30"} bg-gray-200 w-40 h-24 aspect-video bg-cover bg-center bg-no-repeat rounded-md md:mt-0 mr-2 my-2`}>
                            {/* <img src={img.image} className="object-contain " alt="" />  */}
                        </div>
                          )}
                    </div>
                    <div  className='flex-grow w-full    relative'>
                      {/* principal image */}
                      <img className='img object-center object-cover mx-auto h-full rounded-md' src={`${process.env.REACT_APP_ENV=="prod"? 'https://difatours.aynnaka.app':'http://127.0.0.1:8000'}${product?.images?.length >0  && product?.images[activeImage]?.image}`}   alt={product?.images?.length >0  && product?.images[activeImage]?.name} />
                      {/* next */}
                      <button className='absolute top-1/2 bg-white shadow-md -right-3 md:right-0 p-2 disabled:bg-opacity-20  rounded-full ' disabled={activeImage >= product?.images?.length -1} onClick={()=>activeImage < product?.images?.length -1? setActiveImage(activeImage+1) : setActiveImage(product?.images?.length -1) } >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                      {/* prev */}
                      <button className='absolute top-1/2 bg-white shadow-md -left-3 md:left-0 p-2 rounded-full disabled:bg-opacity-20 ' disabled={activeImage <= 0 } onClick={()=>activeImage > 0 ? setActiveImage(activeImage-1) : setActiveImage(0)} >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                      </button>
                    </div>
                  </div>
    
                </div>
              <div className="md:w-1/3 mt-4 md:mt-0 md:mx-2 bg-secondary bg-opacity-20 p-4 rounded-xl">
                    
                    <h1 className="text-3xl uppercase ">{product.name}</h1>
                  
                    <h2 className="text-xl font-bold py-3">{total >0 ? total : product.price} MAD </h2>

                    <div className="p-1">
                      <label htmlFor="" className="opacity-70">QUANTITY</label>
                      <select  onChange={(e) => setQty(e.target.value)} className="mt-2 w-full p-2 pl-6 select border-none outline-none rounded-full" >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>

                      </select>
                    </div>
                    
                    <div className='pt-4'>
                      <p className='opacity-70'>DISCOUNT</p>
                      
                  
                      {
                        discounts.map(d => <div key={d.name} className='p-1'>
                          <input type="radio" name="discount" className='mr-2' id="test1"  value={d.discount} onChange={handleChangeDiscount } defaultChecked={discount===d.discount}  />
                          <label for="test1">{d.name} <span className='text-red-500 pr-3'>({Number(d.discount)*100}%)</span></label>
                          {/* <input type="radio" name="radio-group" id="test1" value={d.discount} onChange={(e)=> console.log(e.target.value)}   />
                          <label for="test1">{d.name} <span className='text-red-500'>({Number(d.discount)*100}%)</span></label> */}
                        </div>)
                      }

                  

                    </div>
             

                    <div>
                      {/* <div className="pt-1 flex">
                        <div className='pr-2'>Status:</div>
                        <div>
                          {product.countInStock > 0 ? <span className='text-green-700'>In Stock</span> : <span className='text-red-700'>Out Of Stock</span> }
                        </div>
                      </div> */}
                    </div>
                    {/* {product.with_ticket &&
                      <div className='pt-4'>
                          <input type="checkbox" value={ticket} onChange={handleChangeDiscount} checked={ticket} /> With Ticket
                      </div>
                      
                      
                    }
                      */}
                      
          
                    {/* <div className='pt-3'>
                      <h1 className='font-600 '>Addons</h1>

                      <div className='pt-1'>
                        {product.addons && product.addons?.map(add => {
                          return (
                            <div className='border rounded-md p-2 my-1 '>
                              <input onChange={handleChangeAddons(add.value,add.name) } type="radio" name='addon' value={add.value} />  {add.name} -  {add.value} DH
                            </div>
                          )
                        }) }
                        <div className='border rounded-md p-2 my-1 '>
                          <input onChange={handleChangeAddons(0,"No addon") } type="radio" name='addon' value={0} />  No addon
                        </div>

                      </div>
                    </div> */}

                    {/* <div>
                      Total : {total}
                    </div> */}
                    
                    <div className="pt-4 ">
                      <button onClick={addToCartHandler} className="w-full overflow-hidden box-border bg-black  p-2 text-white fade-in hover:scale-[1.01]  rounded-full " disabled={product.countInStock <= 0}>
                        <span className=''>ADD TO CART</span>
                      </button>
                    </div>

                    <div className='pt-4'>
                      <div className='pb-2'>
                        Share This Event 
                      </div>

                      <div className='flex items-center'>
                         <button className='px-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13 19.938A8.001 8.001 0 0 0 12 4a8 8 0 0 0-1 15.938V14H9v-2h2v-1.654c0-1.337.14-1.822.4-2.311A2.726 2.726 0 0 1 12.536 6.9c.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H15c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2v5.938zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"/></svg>
                         </button>
                          <button className='px-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.028 1.575a.6.6 0 0 1-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.711-.005 1.316.175 2.669-.645-.335 1.64-.5 2.352-1.214 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.16 15.7-.329 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.973.538z"/></svg>
                          </button>

                          <button className='px-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M8.49 19.191c.024-.336.072-.671.144-1.001.063-.295.254-1.13.534-2.34l.007-.03.387-1.668c.079-.34.14-.604.181-.692a3.46 3.46 0 0 1-.284-1.423c0-1.337.756-2.373 1.736-2.373.36-.006.704.15.942.426.238.275.348.644.302.996 0 .453-.085.798-.453 2.035-.071.238-.12.404-.166.571-.051.188-.095.358-.132.522-.096.386-.008.797.237 1.106a1.2 1.2 0 0 0 1.006.456c1.492 0 2.6-1.985 2.6-4.548 0-1.97-1.29-3.274-3.432-3.274A3.878 3.878 0 0 0 9.2 9.1a4.13 4.13 0 0 0-1.195 2.961 2.553 2.553 0 0 0 .512 1.644c.181.14.25.383.175.59-.041.168-.14.552-.176.68a.41.41 0 0 1-.216.297.388.388 0 0 1-.355.002c-1.16-.479-1.796-1.778-1.796-3.44 0-2.985 2.491-5.584 6.192-5.584 3.135 0 5.481 2.329 5.481 5.14 0 3.532-1.932 6.104-4.69 6.104a2.508 2.508 0 0 1-2.046-.959l-.043.177-.207.852-.002.007c-.146.6-.248 1.017-.288 1.174-.106.355-.24.703-.4 1.04a8 8 0 1 0-1.656-.593zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"/></svg>
                          </button>
                          <button className='px-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 9.55C12.917 8.613 14.111 8 15.5 8a5.5 5.5 0 0 1 5.5 5.5V21h-2v-7.5a3.5 3.5 0 0 0-7 0V21h-2V8.5h2v1.05zM5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-1 2h2V21H4V8.5z"/></svg>
                          </button>

                      </div>

                    </div>

                

                    {/* <div className="pt-4">
                      <button onClick={()=> {}} className="w-full text-center py-2  border border-black  ">ACHETER MAINTENET</button>
                    </div> */}




              </div>

          </div>

          <div className='md:flex pt-6'>

            <div className='md:w-2/3'>
                <div className='flex items-center '>
                  <h1 className=' py-3 italic flex-none pr-4'>Description </h1>
                  <div className='bg-gray-300 h-[1px] rounded-sm flex-grow '></div>

                </div>

                <div className='p-2 '>
          
                  <p className="pt-3 whitespace-pre-line" >
                    {product.description}
                  </p>
                </div>

            </div>
            

          </div>
         
         </>
      )} 
    </div>
  )
}

export default ProductScreen


{/* <div className="">
<div className="p-3">
  <img src={product.image} alt={product.name}  />
</div>
<div >
  <div >
    <div>
      <h3>{product.name}</h3>
    </div>
    <div>
      <Rating
        value={product.rating}
        text={`${product.numReviews} reviews`}
      />
    </div>
    <div>Price: ${product.price}</div>
    <div>
      Description: {product.description}
    </div>
  </div>
</div>

<div className="">
  <div>
    <div >
      <div>
        <div className="flex">
          <div>Price:</div>
          <div>
            <strong>${product.price}</strong>
          </div>
        </div>
      </div>

      <div>
        <div className="flex">
          <div>Status:</div>
          <div>
            {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
          </div>
        </div>
      </div>

      {product.countInStock > 0 && (
        <div>
          <div className="flex">
            <div>Qty</div>
            <div>
              <select
                as='select'
                value={qty}
                onChange={(e) => setQty(e.target.value)}
              >
                {[...Array(product.countInStock).keys()].map(
                  (x) => (
                    <option key={x + 1} value={x + 1}>
                      {x + 1}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </div>
      )}

      <div>
        <button
          onClick={addToCartHandler}
          className='bg-primary px-3 mt-3 py-1'
          type='button'
          disabled={product.countInStock === 0}
        >
          Add To Cart
        </button>
      </div>
    </div>
  </div>
</div>
</div>
<div>
<div >
  <h2>Reviews</h2>
  {product.reviews.length === 0 && <Message>No Reviews</Message>}
  <div >
    {product.reviews.map((review) => (
      <div key={review._id}>
        <strong>{review.name}</strong>
        <Rating value={review.rating} />
        <p>{review.createdAt.substring(0, 10)}</p>
        <p>{review.comment}</p>
      </div>
    ))}
    <div>
      <h2>Write a Customer Review</h2>
      {successProductReview && (
        <Message variant='success'>
          Review submitted successfully
        </Message>
      )}
      {loadingProductReview && <Loader />}
      {errorProductReview && (
        <Message variant='danger'>{errorProductReview}</Message>
      )}
      {userInfo ? (
        <form onSubmit={submitHandler}>
          <div controlId='rating'>
            <label>Rating</label>
            <select
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            >
              <option value=''>Select...</option>
              <option value='1'>1 - Poor</option>
              <option value='2'>2 - Fair</option>
              <option value='3'>3 - Good</option>
              <option value='4'>4 - Very Good</option>
              <option value='5'>5 - Excellent</option>
            </select>
          </div>
          <div controlId='comment'>
            <label>Comment</label>
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <button
            disabled={loadingProductReview}
            type='submit'
            variant='primary'
          >
            Submit
          </button>
        </form>
      ) : (
        <Message>
          Please <Link className="text-blue-900" to='/login'>sign in</Link> to write a review{' '}
        </Message>
      )}
    </div>
  </div>
</div>
</div> */}
