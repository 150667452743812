import React from 'react'

function Partners() {
  return (
    <div className='max-w-screen-xl mx-auto px-8'>
        <div className="max-w-4xl mx-auto py-10 text-center">
        <h1 className='text-2xl font-bold pb-3'>
            NOS PARTENAIRES 
        </h1>
        <p className='opacity-70'>
            Aujourd'hui DIFATOURS MAROC en partenariat avec le groupe NG TRAVEL France et l’ensemble de ses partenaires, se positionne en qualité de développeur du secteur touristique, de nouveaux marchés et garanti les prestations globales liées à la profession.
        </p>
        </div>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-4 pt-8'>
            <div>
                <img src="/boomerang.jpeg" alt="" />
            </div>
            <div>
                <img src="/logo-cappa-club.jpeg" alt="" />
            </div>
            <div>
                <img src="/logo-club-coralia.jpeg" alt="" />
            </div>
            <div>
                <img src="/logo-promo-sejour.jpeg" alt="" />
            </div>
        </div>


    </div>
  )
}

export default Partners