import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SideBar from '../../components/SideBar'
import { createCategory, createTypeCategory, deleteCategory, deleteSubCategory, deleteTypeCategory, listCategory } from '../../actions/categoryActions'
import Axios from 'axios'


const AddNewType = () => {

    // type/add/
    const [name, setName] = useState("")
    const dispatch = useDispatch()

    const handleAddNewType = () => {
        dispatch(createTypeCategory({name}))
    }

    const [filters, setFilters] = useState([])

    useEffect(() => {
        Axios.get(`/api/categories/1/ticket-types/`).then(res=> {
    
          setFilters(res.data.tickettypes)
        }).catch(err=>{
          setFilters([])
        })
       
      }, [])

      const handleDeleteType = (event)  => {
        //  alert("delte")
        // console.log(event.target)
        const removeId = event.target.dataset.remove

        if (removeId){
            dispatch(deleteTypeCategory(removeId))
            
        }
    
      }
    return(
        <div>
            <h1 className='font-bold'>Filters</h1>

            <div className='flex flex-wrap py-2'>
                {filters.map(item => 
                <div key={item._id} className="relative">
                    <button data-remove={item._id} onClick={handleDeleteType}  className='absolute text-xs w-6 h-6 border  -top-3 rounded-full bg-white text-gray-600 right-2'>
                      X
                    </button>
                    <div  className='py-2 px-4 mr-4 mb-4 border rounded-md' >  {item.name}    </div>

                </div>
                )}
            </div>

            <form onSubmit={handleAddNewType} >
                    <div className='border flex '>
                        
                  
                    <input placeholder='Type' className='p-2 outline-none bg-transparent w-full' type="text" value={name} onChange={(e)=>setName(e.target.value)} />
                        <button  className='whitespace-nowrap px-2 bg-black text-white'>
                            Add new filter
                        </button>
                    </div>
                
                </form>
        </div>
    )
}

const CategoryItem = ({category}) =>{
    const dispatch = useDispatch()

    const [openEditCategory, setOpenEditCategory] = useState(false)

    const handleDeleteCategory = (id) => (ev) =>{
        dispatch(deleteCategory(id))
    }

    const handleEditCategory = () => {
        
    }
    
    return (
        <>
            <div className='p-2 relative w-1/4 shadow-md rounded-md  '>
                <div>
                    <img src={`${process.env.REACT_APP_ENV=="prod"? 'https://difatours.aynnaka.app':'http://127.0.0.1:8000'}${category.image}`} />
                    <div className='pt-2'>
                        {category.name} 
                    </div>
                </div>

                <div className='flex'>
                    <button className='text-xs border-b hover:text-blue-600 hover:border-blue-600' onClick={handleDeleteCategory(category._id)}>
                        delete
                    </button>
                    <button className='text-xs border-b hover:text-blue-600 hover:border-blue-600 ml-4' onClick={()=> setOpenEditCategory(true)}>
                        edit
                    </button>
                </div>
                
               
            </div >   

            {/* <ul>
                {category?.subcategories?.map(subcat => <li className='flex justify-between items-center'>
                    <div>
                        {subcat.name}
                    </div>
                    <button className='text-blue-600 border-b text-sm border-blue-600' onClick={handleDeleteSubCategory(subcat._id)}>
                        Delete
                    </button>
                </li>)}
            </ul> */}
        </>
    )
}

const ConfigScreen = ({ history }) => {
   
    const [openAddCategory, setOpenAddCategory] = useState(false)

    const categoryList = useSelector((state) => state.categoryList)
    const dispatch = useDispatch()

    const { loading, error, categories } = categoryList

    const [name, setName] = useState("")
    const [imageCategory, setImageCategory] = useState("")

    useEffect(() => {
      dispatch(listCategory())
    }, [dispatch])

    const handleAddCategory =(event) => {
        event.preventDefault()
        dispatch(createCategory({name,image:imageCategory}))
    }
  
    

  return (
    <>
        <div onClick={()=> setOpenAddCategory(false)} className={`${openAddCategory ? "":"hidden"} w-screen h-screen absolute z-50 bg-black bg-opacity-30`}></div>
        <div className="admin-screen">
            <div className="flex ">
                <div className='w-1/5 '>
                    <SideBar />
                </div>
            
            
                <div className="px-2 md:w-3/5 relative w-full">
                    <h1 className='text-xl pb-1 '>Config </h1>
                    <hr />
                    <div className='pt-3'>
                        <h2 className='font-semibold pb-2'>Categories</h2>
                        <div className="pb-4">
                            <div className='flex gap-3'>
                            {categories?.map(cat => <CategoryItem category={cat} key={cat._id} />)}
                            <div onClick={() => setOpenAddCategory(true)} className={`w-1/4 flex border p-2 items-center justify-center`}>
                                Add new Cat 
                            </div>
                            </div>

                        </div>

                    

                        <div className={`p-4 ${openAddCategory ? "":"hidden"} absolute left-1/2 -translate-x-1/2 z-50 bg-white shadow-md top-1/2 transform -translate-y-1/2`}>
                    
                            <div className='py-4 max-w-md '>
                                New Category : <br />

                                <div className='pt-2'>
                                    <form onSubmit={handleAddCategory}>
                                        <div className='border my-2 '>
                                            <input className='p-2 outline-none bg-transparent w-full' type="file" placeholder='image' onChange={(e)=>setImageCategory(e.target.files[0])} />
                                        </div>
                                        <div className='border my-2 '>
                                            <input className='p-2 outline-none bg-transparent w-full' type="text" placeholder='name' value={name} onChange={(e)=>setName(e.target.value)} />
                                        </div>

                                        <button  className='whitespace-nowrap p-2 bg-black text-white'>
                                            Add Category
                                        </button>
                                    </form>
                                    
                                </div>
                            </div>
                        </div>

                    {/* <div className='py-4'>
                        New Category : <br />

                        <div className='pt-2'>
                            <form onSubmit={handleAddCategory}>
                                <div className='border flex '>
                                    <input className='p-2 outline-none bg-transparent w-full' type="text" value={name} onChange={(e)=>setName(e.target.value)} />
                                    <button  className='whitespace-nowrap px-2 bg-black text-white'>
                                        Add Category
                                    </button>
                                </div>
                                
                            </form>
                            
                        </div>
                    </div> */}

                    {/* <AddSubCategory categories={categories}/>
                    */}

                    <AddNewType />






                </div>
            
                </div>
            </div>
        </div>

     
    </>
  )
}

export default ConfigScreen
