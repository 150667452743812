import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveShippingAddress } from '../actions/cartActions'
import Meta from '../components/Meta'

const ShippingScreen = ({ history }) => {

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,22,21,23]
  const mins = [0,5,10,15,20,30,35,40,45,50,55]

  const [selectedHour, setSelectedHour] = useState(new Date().getHours())
  const [selectedMin, setSelectedMin] = useState(new Date().getMinutes())


  const [firstName, setFirstName] = useState(shippingAddress.firstName)
  const [lastName, setLastName] = useState(shippingAddress.lastName)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [email, setEmail] = useState(shippingAddress.email)

  const [isUnder18, setIsUnder18] = useState(false)
  const [isTermsChecked, setIsTermsChecked] = useState(false)

  const [passportCin, setPassportCin] = useState(shippingAddress.passportCin)
  const [arrivalTime, setArrivalTime] = useState(shippingAddress.arrivalTime)
  const [address, setAddress] = useState(shippingAddress.address)
 
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)

  const dispatch = useDispatch()
  // console.log(shippingAddress)
  const submitHandler = (e) => {
    e.preventDefault()
    let arrivalTime= selectedHour+':'+selectedMin
    
    const shippingAddress = { firstName,lastName,isTermsChecked,isUnder18,phone,email,passportCin,arrivalTime, address, city, postalCode, country }
    
    // shippingAddress.firstName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'')
    dispatch(saveShippingAddress(shippingAddress))
    history.push('/placeorder')
  }

  // const handleChangeFirstName = (e) => {     
  //     setFirstName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))

  // }
  

  return (

    <div className="pt-32 lg:px-0 px-4 container max-w-screen-lg mx-auto">
      <Meta title={"Personal information"} />
      <FormContainer>
        <CheckoutSteps step2  />
        <h1 className="text-2xl font-medium">Personal information </h1>
        <form onSubmit={submitHandler}>
          <div className="my-2">
            <div>First Name</div>
            <input
              className="p-2 w-full  outline-none border "
              type='text'
              placeholder='Enter firstname'
              value={firstName}
              required
              onChange={(e)=>setFirstName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))}
            />
          </div>
          <div className="my-2">
            <div>Last Name</div>
            <input
              className="p-2 w-full  outline-none border "
              type='text'
              placeholder='Enter Lastname'
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))}
            />
          </div>
        
          
          <div className="my-2">
            <div>Phone</div>
            <input
              className="p-2 w-full  outline-none border "
              type='tel'
              pattern='[0-9]*\.?[0-9]*' 
              placeholder='Enter Phone Number'
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ""))}
            />
          </div>

          <div className="my-2">
            <div>Email</div>
            <input
              className="p-2 w-full  outline-none border "
              type='email'
              placeholder='Enter Email Address'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="my-2">
            <div>PASSPORT OR CIN</div>
            <input
              className="p-2 w-full  outline-none border "
              type='text'
              placeholder='Enter PASSPORT or CIN'
              value={passportCin}
              required
              onChange={(e) => setPassportCin(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
            />
          </div>
          
          {/* <div className="my-2">
            <div>Arrival Time</div>

            <div className='flex'>
              <div className='w-32 mr-2'>
                <select value={selectedHour} onChange={(e)=> setSelectedHour(e.target.value)} name="hour" className='p-2  w-full outline-none bg-transparent border' id="hour">
                  <option disabled >Hour</option>
                    {hours.map(hour =><option value={hour} > {String(hour).padStart(2, '0')}  </option> )}
                  
                </select>
              </div>
              <div className='w-32 mr-2'>
                <select value={selectedMin} onChange={(e)=> setSelectedMin(e.target.value) } name="min" className='p-2  w-full outline-none bg-transparent border' id="min">
                  <option disabled>Min</option>
                    {mins.map(min =><option value={min}>{String(min).padStart(2, '0')}  </option> )}
                  
                </select>
              </div>


            </div>
          </div> */}
          <div className="my-2 ">
            
            <input
              id="age"
              className="p-2  outline-none border "
              type='checkbox'
              name='isUnder18'
              value={isUnder18}
              required
              onChange={(e) => {
                setIsUnder18(e.target.checked)
       
              }
              }
            />
            <label className='pl-2' for='age'>Age + 18</label>
            
          </div>

          <div className="my-2 ">
            
            <input
              id="isTermsChecked"
              className="p-2  outline-none border "
              type='checkbox'
              name='isTermsChecked'
              value={isTermsChecked}
              required
              onChange={(e) => {
                setIsTermsChecked(e.target.checked)
       
              }
              }
            />
            <label className='pl-2' for='isTermsChecked'>I have read and agreed to the <a href="/terms-and-conditions" className='text-blue-600' target={"_blank"}>terms and conditions</a></label>
            
          </div>

          <button type='submit' className="p-2 my-2 bg-primary border-primary text-white" >
            Continue
          </button>
        </form>
      </FormContainer>
    </div>
  )
}

export default ShippingScreen
