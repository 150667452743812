import React from 'react'

function ContactUs() {
  return (
    <div className='pt-20'>
     <div className='min-h-[60vh] text-black flex justify-center items-center '>
        <div className='w-1/2 flex items-center justify-center'>
            <div className=''>
                <div className='  '>
                    <h1 className='text-4xl font-bold pb-10'>Contactez-nous </h1>

                    <div>Adresse</div>
                    <p className='pt-2'>
                        2, Rue Ibn Aîcha Marrkaech
                        Maroc, 40 000
                    </p>
                    <div> E-mail</div>
                    <p className='pt-2'> 
                        contact@difatours.ma
                    </p>
                    <div>
                        Téléphone
                    </div>
                    <p className='pt-2'>
                        (+212) 5-24 43 04 18
                    </p>
                    
                </div>
            </div>  
        </div>
        <div className='w-1/2 min-h-[70vh] flex items-center justify-center relative bg-cover bg-no-repeat bg-center' style={{backgroundImage:'url(/contact.jpg)'}}>
            <div className='absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70'></div>
            
            <div className='p-4 w-[340px]  relative bg-white rounded-md'>
                <div className='py-2'>
                    <label className='flex justify-start opacity-60 pb-2' htmlFor="full_name">Votre nom et prénom</label>
                    <div className=' border'>
                        <input type="text" id="full_name" className='w-full bg-transparent outline-none p-2 ' placeholder='Dani almo' />

                    </div>
                </div>
                <div className='py-2'>
                    <label className='flex justify-start opacity-60 pb-2' htmlFor="full_name">Votre adresse mail</label>
                    <div className=' border'>
                        <input type="text" id="full_name" className='w-full bg-transparent outline-none p-2 ' placeholder='test@test.com' />

                    </div>
                </div>
                <div className='py-2'>
                    <label className='flex justify-start opacity-60 pb-2' htmlFor="full_name">Votre numéro de téléphone</label>
                    <div className=' border'>
                        <input type="text" id="full_name" className='w-full bg-transparent outline-none p-2 ' placeholder='+122393322' />

                    </div>
                </div>
                <div className='py-2'>
                    <label className='flex justify-start opacity-60 pb-2' htmlFor="full_name">Votre message</label>
                    <div className=' border'>
                        <textarea type="text" id="full_name" className='w-full bg-transparent outline-none p-2 ' placeholder='Votre message...' />

                    </div>
                </div>

                <div className='pt-4'>
                    <button className='w-full text-white bg-black p-2 rounded-md'>Send</button>
                </div>
               
            </div>
        </div>
        
    </div>

    </div>
  )
}

export default ContactUs