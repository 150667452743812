import React from 'react'
import { Link } from 'react-router-dom'


const Product = ({ product }) => {
  return (
    <Link to={`/product/${product._id}/${product.slug}`}>
      <div className="w-full fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300 rounded-xl aspect- overflow-hidden  shadow-me" css="background: rgb(161, 158, 153);">

        {process.env.REACT_APP_ENV=="prod" ?  
          <img   src={`https://difatours.aynnaka.app${product && product.images && product.images[0]?.image}`} alt={product.image} className=" duration-300 w-full h-full object-center object-cover " />:
         <img   src={`http://127.0.0.1:8000${product && product.images && product.images[0]?.image}`} alt={product.image} className=" duration-300 w-full h-full object-center object-cover " />
        }
       
      </div>

      <div className='p-2'>
        <p className="opacity-70  uppercase font-mono tracking-widest text-md ">{product.name} </p>
       
        <h3 className="font-600   mt-1 text-xl tracking-wider  uppercase">{product.price ===0 ? "Free" : `${product.price } MAD` }</h3>

      </div>
     
   
    {/* </div> */}
    </Link>
  )
}

export default Product
