import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteImage } from '../actions/productActions'

const Photo = ({file}) => {
    return (
        <div className='px-2 '>
            <img src={ URL.createObjectURL(file)} className="w-32 h-32 border-2 border-dashed border-gray-400 object-cover rounded-md" alt="" />
        </div>
    )
}

function Photos({product,productId, setImages}) {
    const [productImages, setProductImages] = useState([]);

   const dispatch = useDispatch()

    const handleDeleteImage = (id)=> (ev) => {
        if (window.confirm('Are you sure')) {
            dispatch(deleteImage(productId,id)) 
        }
    }

    const handleProductFiles = (event) => {
           const data = [];
            for (let i = 0; i < event.target.files.length; i++) {
                data.push(event.target.files[i]);
            }
        setProductImages((old)=> [...old,...data]);
        setImages((old)=> [...old,...data])
    }

    const imageRef = useRef()


    // const uploadFileHandler = async (e) => {
    //     const file = e.target.files[0]
    //     const formData = new FormData()
    //     formData.append('image', file)
    //     formData.append('product_id', product._id)
    
        
     
    //     setUploading(true)
    
    //     try {
    //       const config = {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //         },
    //       }
    
    //       const { data } = await axios.post('/api/products/upload/', formData, config)
    
    //       setImage(data)
    //       setUploading(false)
    //     } catch (error) {
    //       console.error(error)
    //       setUploading(false)
    //     }
    //   }
    return (
        <div>
                <h1 className='text-xl '>Photos </h1>

                <div className='pt-2'>
                    <div className='flex flex-wrap  '>
                        
                        {product?.images?.map( item =>{
                            return ( <div key={item._id} className=" relative  px-2">
                                        <div onClick={handleDeleteImage(item._id)} className='absolute cursor-pointer z-50 -top-3 right-0 w-6 h-6 bg-gray-50 rounded-full ' >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                        <img className='w-32 h-32 object-cover rounded-md ' src={`${process.env.REACT_APP_ENV=="prod"? 'https://difatours.aynnaka.app':'http://127.0.0.1:8000'}${item?.image}`} />
                                    </div>)
                                
                                }
                        )}
                        <div  className='hidden'>
                                <input type="file" ref={imageRef} className='hidden' onChange={handleProductFiles} multiple />
                        </div>
                    
                        {productImages.map((item,id) => <Photo file={item} key={id} />)}
                        
                        <div onClick={() => imageRef.current.click()} className='w-32 h-32 border-2 border-dashed border-blue-400 object-cover rounded-md flex items-center justify-center cursor-pointer bg-gray-50'>
                                <span className='text-gray-400'>  Add Image </span>
                        </div>


                    </div>
                </div>
            
            
        </div>
     
    )
}

export default Photos