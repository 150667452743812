import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { listProducts } from '../actions/productActions'
import Filters from '../components/Filters'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import Paginate from '../components/Paginate'
import Product from '../components/Product'

const Header = () => {
  return (
    <div className='bg-secondary bg-opacity-25 rounded-b-3xl flex flex-col justify-end pb-10 items-center h-32 w-full'>
      {/* <h1 className='text-3xl md:text-4xl text-center max-w-xl '>
        On voyage pour changer, non de lieu, mais d'idées
      </h1> */}
    </div>
  )
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ProductsListScreen({match,history}) {

    const dispatch = useDispatch()
    const location = useLocation()
    
    const pageNumber = match.params.pageNumber || 1 
    
    // const keyword = match.params.keyword || ''
    const keyword = location.search ?  location.search.split("?")[1].split("=")[0] : ""
    // console.log(keyword)
    const category = match.params.category || ''
    console.log(category)
    const subcategory = match.params.sub || ''

    const type = match.params.type || ''

    const { loading, error, products, page, pages } = useSelector((state) => state.productList)

    
    useEffect(() => {
      dispatch(listProducts(category,"",keyword,pageNumber))

    }, [dispatch,pageNumber,category,keyword])


    return (
        <div className=" min-h-screen ">
          <Meta title={"Welcome to Into the wild"} />
          
          <Header />

          <div className='pb-20 xl:px-3 px-8 max-w-screen-xl mx-auto'>

            <div>
              
              <Filters 
                history={history}
                keyword={keyword} 
                category={category}

                />
            </div>

            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <>
            {products.length >0 ? 
                <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid-cols-1 lg:gap-x-6 gap-x-4 lg:gap-y-2 gap-y-1  lg:col-span-3">
                  {products.map((product) => (
                    <div className="group cursor-pointer fade-in text-sm lg:-20 duration-300" key={product._id} >
                      <Product product={product} />
                    </div>
                  ))}
                </div>
                :
                  <div className='flex flex-col items-center justify-center  h-64 '>
                    <img src="/icons/icon-camping.svg" alt="" srcset="" />
                    <div>
                      Nothing Here Yet
                    </div> 
                  </div>
                }

                <Paginate
                  pages={pages}
                  page={page}
                  keyword={keyword}
                  type={type}
                />


                  
                <div>
                  {/* <h1>Here put some content</h1> */}
                </div>
              </>
            )}
          </div>
      </div>
    )
}

export default ProductsListScreen
