import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from '../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from '../constants/orderConstants'
import PaymentForm from '../components/PaymentForm'


const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id


  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { 
    loading: loadingPay,
    success: successPay 
  } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }
    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + (item.price ), 0)
    )
  }

  useEffect(() => {
   
    // const addPayPalScript = async () => {
    //   const { data: clientId } = await axios.get('/api/config/paypal')
    //   const script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
    //   script.async = true
    //   script.onload = () => {
    //     setSdkReady(true)
    //   }
    //   document.body.appendChild(script)
    // }

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch(getOrderDetails(orderId))
    } else if (!order.isPaid) {
      // proceed payment btn hide or show 
    }
  }, [dispatch, orderId, successPay, successDeliver, order])

  const successPaymentHandler = (paymentResult) => {
    
    dispatch(payOrder(orderId, paymentResult))
  }
  console.log(order)
  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }

  // const [hash, setHash] = useState("")

  // const prePayment = (e) => {
  //   e.preventDefault()
  //   console.log("before")
  //   axios.post('/api/payment/prepare',dataForm).then(res=> {
  //     console.log(res.data)
  //     setHash(res.data)
      
  //   }).catch(err=> {
  //     console.log(err)
  //   })
  // }
  console.log(error)
  

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <div className="container  max-w-screen-lg mx-auto pt-32">
      <h1 className=" px-2 text-xl pb-3 font-bold">Order Number : {order._id}</h1>
      <hr />
      <div className="md:flex pt-2">
        <div className="md:w-2/3 w-full pr-2">
          <div >
            <div className="bg-white m-1 p-2 ">
              <h2 className="pb-2  capitalize font-400 text-xl ">Personal Information</h2>
              <p>
                <strong>Name : </strong> {order.shippingAddress?.firstName} {order.shippingAddress?.lastName}
              </p>
              <p>
                <strong>Email : </strong>{' '}
                <a href={`mailto:${order.shippingAddress?.email}`}>{order.shippingAddress?.email}</a>
              </p>
              <p>
                <strong>Phone : </strong>{' '}
                <a href={`tel:${order.shippingAddress?.phone}`}>{order.shippingAddress?.phone}</a>
              </p>
              <p>
                <strong>CIN OR PASSPORT : </strong>{' '}
                {order.shippingAddress?.passportCin}
              </p>
              {/* <p>
                {order.shippingAddress?.type?.toLowerCase() ==="Activities" ?
                <div>
                  <strong>ACTIVITY TIME : </strong>{' '}
                  {order.shippingAddress?.arrivalTime}
                </div>
                :
                <div>
                  <strong>ARRIVAL TIME : </strong>{' '}
                  {order.shippingAddress?.arrivalTime}
                </div>
                
                }
                
              </p> */}
              {/* <p>
                <strong>Address:</strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
                {order.shippingAddress.postalCode},{' '}
                {order.shippingAddress.country}
              </p> */}
              {/* {order.isDelivered ? (
                <Message variant="sucess">
                  Delivered on {order.deliveredAt}
                </Message>
              ) : (
                <div variant="danger">
                  <Message variant='danger' >Not Delivered</Message>
                </div>
                
              )} */}
            </div>

            <div className="bg-white m-1 p-2 ">
              <h2 className='text-xl font-400 '>Payment Method</h2>
              <p className='pt-2'>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant='success'>Paid on {order.paidAt}</Message>
              ) :order.totalPrice ===0 ?
              (
                <div className="py-2 text-green-600 font-bold">
                  Free Activity
                </div>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </div>

            <div className="bg-white m-1 p-2 ">
              <h2 className="py-2 border-b font-600 text-xl">Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                  <div >
                    {order.orderItems.map((item, index) => (
                      <div key={index}>
                        <div className="flex py-2 items-center border-b justify-between">
                          <div className="flex">
                            <img className="h-16 w-16"
                              src={`${process.env.REACT_APP_ENV=="prod"? 'https://difatours.aynnaka.app':'http://127.0.0.1:8000'}${item.image}`}
                              alt={item.name}
                          
                            />
                            <div className="pl-4" >
                              <Link to={`/product/${item.product}`}>
                                {item.name}
                              </Link>
                              <div className='pt-1  opacity-60 text-sm '>
                              
                                <div>
                                  {item.price} MAD
                                </div>
                                {/* <div>
                                  Disount : {item.with_ticket ? "With ticket":"Without ticket"} (+ {item.price_ticket} MAD)
                                </div> */}
                          
                              </div>
                              
                            </div>
                          </div>
                          <div>
                          
                          </div>
                          <div >
                            {item.qty} x ({item.price} + {item.discount} ) MAD  = {item.qty * (parseFloat(item.price) + parseFloat(item.discount)) } MAD
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
               
              )}
            </div>
          </div>
        </div>
        <div className="md:w-1/3 w-full pl-2">
          <div className="bg-white p-2">
            <div >
                
              <h2 className='pb-2  capitalize font-400 text-xl '>Order Summary</h2>
              
              {/* <div className="flex justify-between pt-4">
                  <div className="font-semibold">Items</div>
                  <div>{order.} MAD</div>
              </div> */}
              {/* <div className="flex justify-between pt-2">
                
                  <div className="font-semibold">Shipping</div>
                  <div>${order.shippingPrice}</div>
                
              </div>*/}
              <div className="flex justify-between pt-2">
               
                  <div className="font-semibold">Tax (5%)</div>
                  <div>{order.taxPrice} MAD</div>
               
              </div> 
              <div className="flex justify-between pt-2">
                
                  <div className="font-semibold">Total</div>
                  <div>{order.totalPrice} MAD</div>
              
              </div>

              <div className='pt-4'>
                  {/* <button onClick={payNow} className='p-2 bg-primary text-white w-full'>
                    Pay NOW 

                  </button> */}


                  {order.isPaid ?
                      <div>
                        <div
                          className='bg-green-600 text-center select-none p-2 w-full text-white'
                          // onClick={deliverHandler}
                        >
                          Payment Ok
                        </div>
                      </div>
                  : order.totalPrice !==0 ? 
                    // <PaymentForm title={"Pay Now"} order={order} />
                    <button className='p-2 bg-primary text-white w-full' type='submit'>PAY NOW</button>
                  
                    :
                    <div
                          className='bg-green-600 text-center select-none p-2 w-full text-white'
                          // onClick={deliverHandler}
                        >
                          Order Confirmed
                        </div>
                    
                    }
                </div>
                
                

              {/* {!order.isPaid && (
                <div className="pt-4">
                  {loadingPay && <Loader />}
                
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </div>
              )} */}


              {/* {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid && (
                  <div className="pt-4">
                    <div
                      className='bg-green-600 text-center select-none p-2 w-full text-white'
                      // onClick={deliverHandler}
                    >
                      Payment Ok
                    </div>
                  </div>
                )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderScreen
