import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Paginate from '../../components/Paginate'
import {
  deleteProduct,
  createProduct,
  disableProduct,
  listAdminProducts,
} from '../../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../../constants/productConstants'
import SideBar from '../../components/SideBar'
import getFormatedPrice from '../../utils/getFormatedPrice'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { useHistory } from 'react-router-dom'



const Item = ({product}) => {
  const ref = useRef(null)

  const history = useHistory()

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref,()=> setIsOpen(false))

  const deleteHandler = (id) => (ev) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteProduct(id))
    }
  }

  const handleDisableProduct = (id,isActive) => (ev)  => {
    
    dispatch(disableProduct(id,isActive))

  }

  return (
    <div  className='flex justify-between mb-2 w-full py-2 px-4 border rounded-sm'>
     <div className=''>
        <div className='w-32 float-left'>
          <img src={`${process.env.REACT_APP_ENV=="prod"? 'https://difatours.aynnaka.app':'http://127.0.0.1:8000'}${product?.images[0]?.image}`} className="aspect-video h-full w-full object-cover" alt="" />
        </div>
        <div className='pl-2 float-right'>
          <div>
            {product.name}
          </div>
          <div>
            {getFormatedPrice(product.price)}
          </div>
          <div className='text-sm opacity-70'>
            {product.is_active ? "Active":"Not Active"}
          </div>
          
        </div>
     </div>

      <div className='cursor-pointer relative'>
        <svg xmlns="http://www.w3.org/2000/svg" onClick={()=> setIsOpen(!isOpen)  } className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>

        <div ref={ref} className={`p-2 ${isOpen ? 'block':'hidden'} absolute bg-white shadow-md border z-50 right-0`}>
  
          <button onClick={()=> history.push(`/admin/product/${product._id}/edit/`)} className='whitespace-nowrap hover:bg-gray-50 py-1 px-4'>
            Edit Ticket
          </button>
          <button onClick={handleDisableProduct(product._id,!product.is_active)} className='whitespace-nowrap disabled:bg-gray-100 mb-2 hover:bg-gray-50 py-1 px-4'>
            {product.is_active ? 'Disable':'Activate'}  Ticket
          </button>

          <hr />
          <button onClick={deleteHandler(product._id)} className='whitespace-nowrap text-red-600 hover:bg-gray-50 py-1 px-4'>
            Delete Ticket
          </button>
        </div>
      </div>
     
      
    </div>
  )
}

const ProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  
  const [showModalActions, setShowModalActions] = useState(false)

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct
  } = productCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })

    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }

    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`)
    } else {
      dispatch(listAdminProducts("","",pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ])



  const createProductHandler = () => {
    dispatch(createProduct())
  }

  const showActions = () =>{
    setShowModalActions(!showModalActions)
  }

  return (
    <div className="admin-screen">
      <div className="flex">
        <div className='md:w-1/5'>
          <SideBar  />
        </div>
        
        <div className="px-2 md:w-3/5 w-full">

            <div className='pb-4 flex items-center justify-between'>
              <div className='text-xl font-bold'>
                Products
              </div>
              <div className='border'>
                <form action="" method="post">
                  <input type="text" className='bg-transparent outline-none p-2' placeholder='Search ' />
                  <button className='p-2'>
                    Search 
                  </button>
                </form>
              </div>
            </div>
         
            {loadingDelete && <Loader />}
            {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
            {loadingCreate && <Loader />}
            {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
              <>
            
                {products.map((product) => (
                  <Item key={product._id} product={product} />
                ))}

              <Paginate type={"productlist"} pages={pages} page={page} isAdmin={true} />
            </>
            )} 
        </div>

        <div className='md:w-1/5 px-2'>
          <button onClick={createProductHandler} className='bg-black p-2 text-white'>
            Add New Product
          </button>


        </div>

      </div>

    </div>
  )
}

export default ProductListScreen
