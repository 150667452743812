import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function Filters({history,keyword,category}) {


    const loc = useLocation()

  
    const [ticketTypes, setTicketTypes] = useState([])
    const handleFilterChange = (cat) => (e)=> {
   
        if (cat.trim() && cat.toLowerCase() !="all"){
          history.push(`?${cat}`)
        }else {
          history.push(loc.pathname)
        }
    }

    useEffect(() => {
        axios.get(`/api/categories/${category}/ticket-types/`).then(res=> {
    
          setTicketTypes(res.data.tickettypes)
        }).catch(err=>{
          setTicketTypes([])
        })
       
      }, [])

  return (
    ticketTypes.length >0?
    <div className='py-4 items-center flex flex-wrap pb-8'>
    
        {/* <div className=' text-sm md:text-md mt-2 uppercase font-meduim '>
          DAY :
        </div>  */}
              <div  onClick={handleFilterChange("all")}  className={`p-2 mt-2  border cursor-pointer text-sm  rounded-md mr-2 ${keyword ==="" ? "bg-primary text-white":"hover:bg-primary hover:bg-opacity-30"} `}>
                All DAYS
              </div>
            {
            
           
            ticketTypes.map((op,id) => <div key={id} onClick={handleFilterChange(op.slug)} className={`p-2 mt-2 border cursor-pointer text-sm  rounded-md mx-2 ${keyword ===op.slug ? "bg-primary text-white":"hover:bg-primary hover:bg-opacity-30"} `}>
                {/* <label  htmlFor={op.label} >{op.label}</label>
                <input name='cat' id={op.label} className='bg-transparent hidden' value={op.value} type="radio" />   */}
                <div className='uppercase  text-sm md:text-md'>
                    {op.name}
                </div>
            </div> )}

        {/* <button className='p-2 bg-secondary  mt-2 border cursor-pointer text-sm  rounded-md mx-2' onClick={handleFilterChange}>Ok</button> */}
    
    </div>
    :
    <div>

    </div>
  )
}

export default Filters