import React from 'react'
import { Link } from 'react-router-dom'

const Paginate = ({ pages, page, type,isAdmin = false, keyword = '' }) => {
  return (
   
    pages > 1 && (
      <div className='flex justify-end pt-8'>
        {[...Array(pages).keys()].map((x) => (
          <Link
            key={x + 1}
            to={
              !isAdmin
                ? keyword
                  ? `&page=${x + 1}`
                  : `?keyword=${keyword}&page=${x + 1}`
                : `/admin/${type}/${x + 1}`
            }
          >
             {/* active={x + 1 === page} */}
            <div className={` border p-1 w-8 mr-2 hover:bg-opacity-90 flex items-center justify-center  rounded-md ${x+1 ===page ? "bg-primary text-white":""}`}>{x + 1}</div>
          </Link>
        ))}
      </div>
    )
  )
}

export default Paginate
