import axios from 'axios'
import * as CAT from "../constants/categoryConstants"
import { logout } from './userActions'

if (process.env.REACT_APP_ENV=="prod"){
  axios.defaults.baseURL = 'https://difatours.aynnaka.app'
}else{
  axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

export const createCategory = (category) => async (dispatch, getState) => {
      try {
      dispatch({
        type: CAT.CATEGORY_CREATE_REQUEST,
      })

      console.log(category)
  
      const {
        userLogin: { userInfo },
        categoryList:{categories},
      } = getState()
  
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const form = new FormData()

      form.append("name",category.name)
      form.append("image",category.image)

      const { data } = await axios.post(`/api/categories/add/`, form, config)
  

     dispatch(listCategory())
    
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: CAT.CATEGORY_CREATE_FAIL,
        payload: message,
      })
    }
  }
  

export const deleteCategory = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CAT.CATEGORY_DELETE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
    const { data } = await axios.delete(`/api/categories/${id}/delete/`, config)
  

     dispatch(listCategory())
    
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: CAT.CATEGORY_DELETE_FAIL,
        payload: message,
      })
    }
  }

export const deleteSubCategory = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CAT.CATEGORY_DELETE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
    const { data } = await axios.delete(`/api/categories/sub/${id}/delete/`, config)
  

     dispatch(listCategory())
    
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: CAT.CATEGORY_DELETE_FAIL,
        payload: message,
      })
    }
  }


export const listCategory = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CAT.CATEGORY_LIST_REQUEST,
        })

        const {
        userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            //   Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/categories/`, config)

        dispatch({
            type: CAT.CATEGORY_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        const message =
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        if (message === 'Not authorized, token failed') {
        dispatch(logout())
        }
        dispatch({
        type: CAT.CATEGORY_LIST_FAIL,
        payload: message,
        })
    }
}

export const createSubCategory = (idCat,subCategory) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CAT.CATEGORY_CREATE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
        categoryList:{categories},
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.post(`/api/categories/${idCat}/sub/add/`, subCategory, config)
  

     dispatch(listCategory())
    
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: CAT.CATEGORY_CREATE_FAIL,
        payload: message,
      })
    }
  }
  
export const createTypeCategory = (type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAT.CATEGORY_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
      categoryList:{categories},
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/categories/type/add/`, type, config)


    dispatch(listCategory())
  
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CAT.CATEGORY_CREATE_FAIL,
      payload: message,
    })
  }
}
  
export const deleteTypeCategory = (id) => async (dispatch, getState) => {
  try {
 
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

  const { data } = await axios.delete(`/api/categories/type/${id}/delete/`, config)


   dispatch(listCategory())
  
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CAT.CATEGORY_DELETE_FAIL,
      payload: message,
    })
  }
}