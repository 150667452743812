import React from 'react'
import Partners from '../components/Partners'

function AboutUs() {
  return (
    <div className=''>
        <div className='h-80 flex relative items-center bg-center bg-cover bg-no-repeat justify-center bg-gray-50' style={{backgroundImage:"url(/images/kser.png)"}}>
            <div className='absolute top-0 bg-white bg-opacity-70 right-0 left-0 bottom-0 '>

            </div>
            <div className='relative pt-10'>
                <h1 className='text-5xl'>
                    A propos de nous
                </h1>
            </div>
           
        </div>

        <div className='max-w-screen-lg px-4 mx-auto md:flex pt-20'>

            <div className='md:w-1/2'>
                <img src="/im1.png" alt="" />
            </div>

            <div className='md:w-1/2'>

                <h1 className='text-3xl pb-4 font-bold'>
                    Qui nous sommes !
                </h1>
                <p>
                    Une agence réceptive globale du tourisme au Maroc, DIFATOURS MAROC a construit son expertise depuis 1987 en valorisant les potentialités touristiques du Maroc, en multipliant les formules de voyages et les services associés répondant ainsi aux motivations de ses clients partenaires provenant des marchés émetteurs en incoming. Nous mettons à votre service notre savoir-faire façonné sur des décennies d'expérience, des équipes dynamiques et professionnelles, des moyens logistiques performants. Nos deux agences sont implantées sur les villes à Marrakech et Agadir, deux pôles stratégiques du tourisme pour mieux veiller au déroulement des vacances de vos clients et répondre immédiatement à leurs attentes, aux vôtres en qualité de producteurs. Aujourd'hui DIFATOURS MAROC en partenariat avec le groupe NG TRAVEL France et l’ensemble de ses partenaires, se positionne en qualité de développeur du secteur touristique, de nouveaux marchés et garanti les prestations globales liées à la profession.
                </p>
                <p className='pt-2'>
                    DIFATOURS MAROC lance cette année son service OUT GOING sous son label T’ES CLUBS VACANCES sur des destinations telles que la Tunisie, l’Egypte et le reste du monde pour les voyageurs nationaux.            
                </p>
            </div>

        </div>
        <div className='py-20'>
            <Partners />    
        </div>

        <div className='py-20 max-w-screen-lg mx-auto'>
            <div className='pb-8 text-center max-w-2xl mx-auto'>
                <h1 className='text-2xl font-bold'>
                    Notre profil : des engagements professionnels
                </h1>
                <p className='pt-2'>
                    Sa vocation, ses choix, sa charte professionnelle, Sa force, ses recherches de propositions nouvelles, Ses alliances internationales, ses développements.
                </p> 
            </div>
           


            <div className='grid grid-cols-1 md:grid-cols-3 gap-8 px-4 md:gap-8'>
                <div className='p-4 bg-secondary rounded-md bg-opacity-10 shadow-md'>
                    <div className='text-xl'>
                        SA VOCATION, SES CHOIX, SA CHARTE PROFESSIONNELLE,
                    </div>

                    <div className='pt-3 text-sm'>
                        DIFATOURS MA fait de la qualité, une véritable expertise, un enjeu de développement afin d'offrir de réelles garanties dans toutes les phases opérationnelles de son rôle en assistance sur la destination. Elle se positionne en qualité de poste productif pour ses partenaires constamment à la recherche de nouveaux produits et en s’adaptant aux besoins de ses clients. Nous mettons notre expertise de voyagistes et notre connaissance profonde du Maroc aux services de vos clients.
                    </div>

                </div>
                <div className='p-4 bg-secondary rounded-md bg-opacity-10 shadow-md'>
                    <div className='text-xl '>
                        SA VOCATION, SES CHOIX, SA CHARTE PROFESSIONNELLE,
                    </div>

                    <div className='pt-3 text-sm'>
                        DIFATOURS MA fait de la qualité, une véritable expertise, un enjeu de développement afin d'offrir de réelles garanties dans toutes les phases opérationnelles de son rôle en assistance sur la destination. Elle se positionne en qualité de poste productif pour ses partenaires constamment à la recherche de nouveaux produits et en s’adaptant aux besoins de ses clients. Nous mettons notre expertise de voyagistes et notre connaissance profonde du Maroc aux services de vos clients.
                    </div>

                </div>
                <div className='p-4 bg-secondary rounded-md bg-opacity-10 shadow-md'>
                    <div className='text-xl'>
                        SA VOCATION, SES CHOIX, SA CHARTE PROFESSIONNELLE,
                    </div>

                    <div className='pt-3 text-sm'>
                        DIFATOURS MA fait de la qualité, une véritable expertise, un enjeu de développement afin d'offrir de réelles garanties dans toutes les phases opérationnelles de son rôle en assistance sur la destination. Elle se positionne en qualité de poste productif pour ses partenaires constamment à la recherche de nouveaux produits et en s’adaptant aux besoins de ses clients. Nous mettons notre expertise de voyagistes et notre connaissance profonde du Maroc aux services de vos clients.
                    </div>

                </div>
            </div>

        </div>

        
    </div>
  )
}

export default AboutUs