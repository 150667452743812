import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
import Welcome from '../components/Welcome'
import ContactUs from '../components/ContactUs'
import Video from '../components/Video'
// import Hero from '../components/Hero'


const Services = () => {
  return (
    <div className='py-20 rounded-xl px-8 ' id='services'>
      <div className='flex pb-4 justify-center'>
        <h1 className='text-4xl text-center font-bold'>NOTRE SERVICES</h1>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-8 '>
        <div className='bg-white p-4 rounded-md shadow-md '>
          <div className='w-32 mx-auto'>
            <img src="/icons/ic_airline.svg" alt="" />
          </div>
          <h1 className='font-bold text-xl uppercase py-2'>CONSEILLER SES PARTENAIRES</h1>
          
          <p className='text-sm pt-3'>
              A travers la bonne réalisation des services commandés. Grâce à ses collaborateurs sélectionnés et formés aux exigences du secteur et métier, constamment sollicités par des formations sur le terrain et en interne.
          </p>
        </div>
        <div className='bg-white p-4 rounded-md shadow-md'>
          <div className='w-32 mx-auto'>
            <img src="/icons/ic_travel.guide.svg" alt="" />
          </div>
          <h1 className='font-bold text-xl uppercase py-2'>ASSURER L'OPÉRATIONNEL</h1>
         
          <p className='text-sm pt-3'>
            A travers la bonne réalisation des services commandés.Grâce à ses collaborateurs sélectionnés et formés aux exigences du secteur et métier, constamment sollicités par des formations sur le terrain et en interne.
          </p>
        </div>
        <div className='bg-white p-4 rounded-md shadow-md'>
          <div className='w-32 mx-auto'>
            <img src="/icons/ic_festival.svg" alt="" />
          </div>
          <h1 className='font-bold text-xl uppercase py-2'> CELLULES OPÉRATIONNELLES</h1>
         
          <p className='text-sm pt-3'>
            Grâce à ses collaborateurs sélectionnés et formés aux exigences du secteur et métier, constamment sollicités par des formations sur le terrain et en interne.
          </p>
        
        </div>
      </div>
    </div>
  )
}

const Hero = () => {
  return (
        <div className='bg-no-repeat '>
            <Video src={'./videos/video.mp4'} poster={'./videos/poster.png'} />
            
            {/* <div className='absolute left-5 md:left-10  md:top-1/2 top-4/5 transform -translate-y-1/2 max-w-md bg-white shadow-md p-4'>
             
              <div className='pl-10'>
                <p class="pb-2 text-2xl font-bold pt-4 opacity-80">AGENCE DE VOYAGE &amp; RECEPTIVE</p>
                <p class="opacity-75 pr-8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt hic laudantium consectetur, atque ?</p>
              </div>
             
              <div className='absolute top-0 right-0 '>
                <img src="/logo_icon.png" className='w-20 h-20' alt="" />
              </div>
            </div> */}
          
        </div>

        
  )
}

const Presentation = () => {
  return (
 
      
        <div className='bg-cover relative bg-no-repeat bg-center' style={{backgroundImage:'url(/presentation.jpg)'}}>
              <div className='w-full h-full absolute z-0 bg-opacity-90 bg-white'>

              </div>
              <div className='px-4 max-w-screen-xl mx-auto py-10 relative'>
                <h1 className='text-4xl font-extrabold leading-10 text-center uppercase '>difa tours maroc</h1>
                <h2 className='text-center pt-1 pb-4'>
                  Agence de voyage & réceptive
                </h2>
                <div className='md:flex  items-center '>
                  <div className='hidden md:block md:w-1/2 relative'>
                    <img src="/im1.png" className=' ' alt="" srcset="" />
                    {/* <img src="/2.jpeg" className='w-2/3 ' alt="" srcset="" /> */}
                  
                  </div>
                  <div className='md:w-1/2'>
                    <p>
                      Notre expertise à votre disposition afin de vous proposer et garantir des services opérationnels sur la destination, des voyages réussis pour vos clients.
                    </p>
                    <p>
                     Nos atouts reconnus sont 
                    </p>

                    <div className='flex gap-x-10 pt-10'>
                      <div className='flex items-center'>
                        <img src="/icons/1.png" className='w-12' alt="" srcset="" />
                        <span>
                          Savoir organiser
                        </span>
                      </div>
                      <div className='flex items-center'>
                        <img src="/icons/2.png" className='w-12' alt="" srcset="" />
                        <span>Gérer</span>
                      </div>
                      <div className='flex items-center'>
                        <img src="/icons/3.png" className='w-12' alt="" srcset="" />
                        <span>Proposer</span>
                      </div>
                      
                     
             
                    </div>

                  </div>
                  
                </div>
            
            

            </div>

           
        </div>
        
      
    

  )
}


function HomeScreen({history}) {
  
  const [categories, setCategories] = useState([])

 
  useEffect(() => {
    axios.get('/api/categories/').then(res=> {

      setCategories(res.data.categories)
    }).catch(err=>{
      setCategories([])
    })
   
  }, [])
  
  
  return (
    <div className=''>
      <Hero /> 

      <Presentation />
      <div className='px-8 py-4 max-w-screen-xl mx-auto  '>
          
          <Services />
          
          <div id='packs'  className={`pb-10 pt-10 grid md:grid-cols-3 grid-cols-1 lg:gap-x-6 gap-x-4 lg:gap-y-2 gap-y-1  lg:col-span-3`}>
            <div className=''>
              <h1 className='text-4xl font-bold'>Nos excursions</h1>
              <p className='pt-4'>
                Découvrez nos dernières excursions
              </p>
            </div>
            {categories.map(cat => 
            <div key={cat._id} className='hover:shadow-md rounded-md pb-3 lg:-20 '>
              <Link  to={`/category/${cat._id}`} >
                
                {/* <div className="mx-auto fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300 aspect-video overflow-hidden shadow-me" css="background: rgb(161, 158, 153);">
                  <img src={`${cat.image}`} alt={"image"} className="duration-300 rounded-md w-full h-full object-center object-cover " />
                </div> */}
                <div className="w-full fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300 rounded-xl overflow-hidden  shadow-me">

                 { process.env.REACT_APP_ENV=="prod" ?  
                    <img src={`https://difatours.aynnaka.app${cat.image}`} className=" duration-300 w-full h-full object-center object-cover " />:
                    <img src={`http://127.0.0.1:8000${cat.image}`} className=" duration-300 w-full h-full object-center object-cover " />
                  }
       
                 
                </div>

                <div className='p-2'>
                  <h1 className='text-xl py-2 opacity-80 font-bold capitalize' >{cat.name} (10)</h1>
                  <p className='text-sm'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, animi. 
                  </p>
                </div>
                
              </Link>
            </div>
            )}
            
            
          </div>


          {/* <Partners /> */}

        

      </div>

   

      <ContactUs />
    </div>
  )
}

export default HomeScreen