import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { addImages, disableProduct, listProductDetails, updateProduct } from '../../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../../constants/productConstants'
import SideBar from '../../components/SideBar'
import Photos from '../../components/Photos'

// const uploadFileHandler = async (e) => {
    //     const file = e.target.files[0]
    //     const formData = new FormData()
    //     formData.append('image', file)
    //     formData.append('product_id', product._id)
    
        
     
    //     setUploading(true)
    
    //     try {
    //       const config = {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //         },
    //       }
    
    //       const { data } = await axios.post('/api/products/upload/', formData, config)
    
    //       setImage(data)
    //       setUploading(false)
    //     } catch (error) {
    //       console.error(error)
    //       setUploading(false)
    //     }
    //   }


const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id
  const [images, setImages] = useState([])

  const [name, setName] = useState('')

  const [price, setPrice] = useState(0)
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [ticketType, setTicketType] = useState('')


  
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [ticketTypes, setTicketTypes] = useState([])

  const [withticket, setWithticket] = useState(false)
  const [priceticket, setPriceticket] = useState(0)



  const [countInStock, setCountInStock] = useState(0)
  const [description, setDescription] = useState('')

  const dispatch = useDispatch()
 
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  
  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/productlist')
    } else {
      if (!product.name || product._id !== Number(productId)) {
        dispatch(listProductDetails(productId))
      } else {
        setName(product.name)
        setWithticket(Boolean(product.with_ticket))
        setPriceticket(product.price_ticket)

        product?.ticket_type && setTicketType(product?.ticket_type)

        product?.subcategory && setSubCategory(product?.subcategory)
        setPrice(product.price)
        setCategory(product?.category?._id)
   
        setCountInStock(product.countInStock)
        setDescription(product.description)
      }
    }
  }, [dispatch, history, productId, product, successUpdate])

 
  useEffect(() => {
    axios.get('/api/categories/').then(res=> {

      setCategories(res.data.categories)
    }).catch(err=>{
      setCategories([])
    })
   
  }, [])

  useEffect(() => {
    axios.get(`/api/categories/1/ticket-types/`).then(res=> {

      setTicketTypes(res.data.tickettypes)
    }).catch(err=>{
      setTicketTypes([])
    })
   
  }, [])

  useEffect(() => {
    if (category){
      axios.get(`/api/categories/${category}/sub/`).then(res=> {
        console.log(res.data)
        setSubCategories(res.data.subcategories)
      }).catch(err=>{
        setSubCategories([])
      })
    }
  
  }, [category])

  

  const handleDisableProduct = (isActive) =>(ev)=> {
    dispatch(disableProduct(product._id,isActive))
  }

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        subCategory,
        category,
        ticket_type:ticketType,

        withticket,
        priceticket,
        
        description,
        countInStock,
      },images)
    )
    // console.log(images)
    // dispatch(addImages(productId,images))
   
    //   const config = {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   }

    //   const formData = new FormData()
      
    //   for (let i; i< images.length ; i++){
    //     console.log(images[i])
    //     formData.append('images', images[i])
    //   }
      

    //   axios.post(`/api/products/${productId}/images/`, formData, config).then(res=> {
    //     console.log(res)
    //   }).catch(err=>{
    //     console.log(err)
    //   })
    //   console.log("images added")

    }
  




  return (
    <>
      <div className="admin-screen">
        <div className="flex ">
          <div className='md:w-1/5 '>
            <SideBar />
          </div>
           
          
          <div className="md:w-4/5 px-4">
            <div className='flex justify-between'>
            <h1 className='text-xl font-600 pb-4'>Edit Product</h1>
            <div>
            <div class="flex items-center justify-center w-full mb-12">
              <label onClick={handleDisableProduct(!product?.is_active)} for="toggleB" class="flex items-center cursor-pointer">
              
                <div class="relative">
                  <input type="checkbox" checked={product?.is_active} id="toggleB" class="sr-only" />
                  <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                  <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                </div>
             
              </label>
            </div>

            </div>
            </div>
            
            
            
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form className='relative'>
                
                <div className='p-4 border rounded-md '>
                  <Photos setImages={setImages} product={product} productId={productId} />
                </div>

                <div className='border rounded-md mt-3 p-2'>

                  <h1 className='text-xl pb-3'>
                    Infos  
                  </h1>

                  <div className='py-2'>
                    <label className=''>Name</label>
                    <div className='border rounded-md mt-1'>
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Enter name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                   
                  </div>

                 

                

                  <div className='my-2 '>
                    <label className=''>Price</label>
                    <div className='border rounded-md'>
                      <input
                          className='p-2 w-full outline-none bg-transparent'
                          type='number'
                          placeholder='Enter price'
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>
                  
                  </div>
                    
                  <div className=' my-2 '>
                    <label className=' '>Quantity</label>
                    <div className='border rounded-md'>
                      <input
                        type='number'
                        className='p-2 w-full outline-none bg-transparent'
                        placeholder='Enter countInStock'
                        value={countInStock}
                        onChange={(e) => setCountInStock(e.target.value)}
                      />
                    </div>
                   
                  </div>
                  <div className='my-2'>
                    <div className='py-2 flex justify-between'>
                      <label className=''>Category</label>
                    
                    </div>
                  
                    <div className='border required:border-red-600  rounded-md mt-2'>
                      <select
                          value={category} 
                          onChange={(e) => setCategory(e.target.value)} className='w-full p-2 outline-none bg-transparent' name="cat" id="cat" required>
                        <option value="" disabled>Select Category  </option>
                        {categories?.map((op,index)=> <option key={index} value={op._id}>{op.name}</option> )}
                      </select>
                    </div>

    
                  </div>

                  {/* <div className=' my-2 '>
                    <label for="type" className=''>Sub Category </label>
                    <div className='border rounded-md'>
                        <select  
                          value={subCategory}
                          
                          onChange={(e) => setSubCategory(e.target.value)}
                          className='w-full p-2 outline-none bg-transparent' name="type" id="type" required>
                          <option  disabled>Select Sub Category</option>
                          
                          {subCategories?.map((sub,index)=> <option key={index} value={sub._id}>{sub.name}</option> )}
                      
                      </select>
                    </div>
                 
                  </div>  */}

                  <div className=' my-2 '>
                    <label for="type" className=''>Product Type</label>
                    <div className='border rounded-md'>
   
                        <select  
                          value={ticketType}
                          
                          onChange={(e) => setTicketType(e.target.value)}
                          className='w-full p-2 outline-none bg-transparent' name="type" id="type">
                          <option  value="" disable>Select Product type </option>
                          {ticketTypes?.map((type,index)=> <option key={index} value={type._id}>{type.name} </option> )}
                      
                      </select>
                    </div>
                 
                  </div>

                  {/* <div className='my-2'>
                    <h1 className='pb-3'>Addons</h1>
                    <div>

                      With Ticket : <input type="checkbox" value={withticket} onChange={()=> setWithticket(!withticket) } checked={withticket} /> <br />
                      {
                      withticket ?
                        <div>
                          Price ticket : <input type="number" value={priceticket} onChange={(e)=> setPriceticket(e.target.value)} className='p-2 outline-none bg-transparent border' placeholder='Ticket price'/>
                        </div>:
                        ""
                      }
                    </div>


                  </div> */}

                </div>

                <div className='p-2 mt-3 border rounded-md'>
                  <div className=' my-2'>
                    <label className=''>Description</label>
                    <div className='border rounded-md mt-2'>
                      <textarea
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        rows={10}
                        placeholder='Enter description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  
                  </div>
                </div>
               
           
                <div className='fixed border-t bottom-0 w-4/5 right-0  bg-white'>
                 <div className='flex p-2 justify-between items-center'>
                    <h1 className='text-2xl'>
                      {name}
                    </h1>
                    <button onClick={submitHandler} className="rounded-md py-2 px-8 text-white  bg-primary">
                      Update 
                    </button>
                 </div>
                </div>
             


              </form>
              
            )}
               
                {/* addons */}
                {/* <div className='py-2'>
                  <h1 className='text-xl font-600  '>
                    <span className='border-b'>Addons</span>
                  </h1>
                
                  <div className='flex pt-3 flex-wrap w-full overflow-x-scroll gap-3'>
                
                    {product?.addons?.map( item =>{
                        return ( <div className="mr-3 relative ">
                                  <div onClick={handleDeleteAddon(item._id)} className='absolute w-6 h-6 bg-gray-100 hover:bg-gray-200 flex items-center justify-center rounded-full -top-2 -right-2' >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                  </div>
                                   <div className='px-6 py-2 border rounded-md'>
                                     {item.name} | {item.value} DH
                                   </div>
                                  </div>
                                  )
                              
                            }
                      )}
                    
                  </div>

                  <div className=' py-4'>
  
                    <div className='pt-2 flex'>
                      <div className='border rounded-md mr-2'>
                        <input placeholder='addon' className='p-2 outline-none bg-transparent' type="text" value={option.name} onChange={(e)=> setOption({...option,name:e.target.value})} />
                      </div>
                      <div className='border rounded-md '>
                        <input placeholder='price' className='p-2 outline-none bg-transparent' type="text" value={option.value} onChange={(e)=> setOption({...option,value:e.target.value})} />
                      </div>
                      <button className="bg-primary  mx-2 rounded-md text-white w-10 flex items-center justify-center" disabled={option.value ==="" && option.name ===""}  onClick={handleAddNewOption}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                        </svg>
                      </button>
                    </div>

                  </div>

                </div> */}

          </div>
        </div>
      </div>
          
     
    </>
  )
}

export default ProductEditScreen
