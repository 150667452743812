import React, { useEffect, useRef } from 'react'
import { Link, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import { useState } from 'react'
import useOnClickOutside from '../hooks/useOnClickOutside'


const Logo = () => {
    return (
        <Link to={"/"}>
            {/* <div className='text-xl text-white font-bold font-mono'>
                Diffa
            </div> */}
            <img className='w-40' src="/logo.png" alt="into the wild epic events" srcSet="/logo.png" />
        </Link>
    )
}

function Header() {
    const dispatch = useDispatch()
    const [isToggled, setIsToggled] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)

    const [isAdmin, setIsAdmin] = useState(false)

    const [isHeaderSticky, setIsHeaderSticky] = useState(false)

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const { cartItems } = useSelector((state) => state.cart)
    const logoutHandler = () => {
        dispatch(logout())
    }

    const toggleMenu = () =>{
        setIsToggled(!isToggled)
    }
    const handleScroll = () => {
        if (window.pageYOffset > 40) {
           setIsHeaderSticky(true)
        }else{
           setIsHeaderSticky(false)
        }
    }
    const [isFixed, setIsFixed] = useState(true)
    const location = useLocation();
    useEffect(() => {

        console.log(location.pathname)
        if (location.pathname =="/" || location.pathname.includes("category") || location.pathname.includes("about-us") ){
            setIsFixed(false)
        }else{
            setIsFixed(true)
        }
        
    }, [location])
    

    useEffect(() => {

        window.addEventListener("scroll", handleScroll);
    
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])

    const refMenu = useRef()

    useOnClickOutside(refMenu, ()=> setIsToggled(false))

    const handleOpenMenu = () => {
        setOpenMenu(!openMenu)
    }
  

    return (
        location.pathname.split("/")[1] != "admin"  ?
            <div className={`flex z-50  fixed w-full ${ !isFixed && isHeaderSticky ? 'bg-white shadow-md ':'text-black'} ${isFixed ? "bg-white ":""}  px-8 py-4 justify-between items-center`}>
            
            <div className='flex items-center '>
                
                {/* menu mobile */}

                <div className={` ${openMenu ? "":"hidden"}`}>
                    <div onClick={()=> setOpenMenu(false)} className='z-30 absolute w-screen h-screen bg-black bg-opacity-40 left-0 top-0'>
                    </div>

                    <div className={` md:hidden h-full  px-8 py-4 border shadow-md fixed bottom-0 z-50 w-1/2 left-0 p-3 bg-white`}>
                        
                        <div className='flex items-center'>
                            <div onClick={handleOpenMenu} className=' '>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
                                </svg>
                            </div>
                            <Logo />
                        </div>
                       
                        <div className='pt-10'>
                            <a href='/#services' className=' text-black'>
                                Nos Services
                            </a>
                        </div>
                        <div className='pt-4'>
                            <a href="/#packs" className=' text-black'>
                                Packs
                            </a>
                        </div>
                        <div className='pt-4'>
                            <a href="/#contact" className=' text-black'>
                                Concat us
                            </a>
                        </div>
                        
                        
                    </div>
                </div>
               

                <div onClick={handleOpenMenu} className='block md:hidden'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
                    </svg>
                </div>
                <Logo />

              
            
            </div>

            <div className='md:flex items-center text-black text-xl  hidden'>
                <a href='/' className='px-4 text-primary text-xl'>
                    Accueil
                </a>
               
                <a href="/#services" className='px-4 '>
                    Nos services
                </a>
              
                <a href="/#packs" className='px-4 '>
                    Nos excursions
                </a>
                <a href="/about-us" className='px-4 '>
                    À propos de nous
                </a>

              
            </div>
        

            <div className='flex items-center '>

                 <a href="/#packs" className='py-1 px-4 bg-primary rounded-md text-xl '>
                    Buy Now
                </a>
{/*             
                {userInfo ?
                <div ref={refMenu} className='relative cursor-pointer select-none' onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>   
                    <div className={`${isToggled ? '':"hidden"} absolute bg-gray-100 text-secondary px-4 rounded-md p-2   right-0 z-50 `}>
                    
                        {userInfo && (
                            <div className="  text-xl whitespace-nowrap p-2 w-24">
                                <div className="" >
                                    <Link to="/profile" className="pr-3">
                                        My profile
                                    </Link>
                                </div>
                                {userInfo.isAdmin && (
                                    <div>   
                                        <Link to='/admin/'>
                                            <div>Admin</div>
                                        </Link>
                                    
                                    </div>
                                    )
                                }
                                <div>
                                    <button onClick={logoutHandler}>
                                        Logout
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>              
                </div>:
                <Link to={"/login"}>
                    <div>
                                
                    </div>
                </Link>
                    
                } */}
                
                {/* <div className='pl-3'>
                    <Link className="flex" to="/cart">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                        <div>
                            {
                                cartItems && cartItems.length && 
                                    <i>{cartItems.length }</i>
                            }
                        </div>
                    </Link>
                </div> */}

            </div>
            
        </div>:
        
        <div>
                
        </div>
    )
}

export default Header