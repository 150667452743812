import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { Link } from 'react-router-dom'

const ProfileScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const orderListMy = useSelector((state) => state.orderListMy)
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails('profile'))
        dispatch(listMyOrders())
      } else {
        setName(user.name)
        setEmail(user.email)
      }
    }
  }, [dispatch, history, userInfo, user, success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, password }))
    }
  }

  return (
    <div className="pt-32">
      <div className="flex flex-row">
        <div className="px-3 w-1/3" >
          <h2 className="p-2 bg-gray-200">User Profile</h2>
          {message && <Message variant='danger'>{message}</Message>}
          {}
          {success && <Message variant='success'>Profile Updated</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <form className="" onSubmit={submitHandler}>
              <div className="   w-full mt-2">
                <label className="bg-gray-100 px-2 py-1 ">Name</label>
                <div className="border">
                  <input
                  
                    className="outline-none bg-transparent p-2 "
                    type='text'
                    placeholder='Enter name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                </div>
               
              </div>

              <div className=" w-full mt-2">
                <label className="bg-gray-100 px-2 py-1 ">Email Address</label>
                <div className="border">
                  <input
                    type='email'
                    className="outline-none bg-transparent p-2  w-full"
                    placeholder='Enter email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
               
              </div>

              <div className=" w-full mt-2">
                <label className="bg-gray-100 px-2 py-1 ">Password</label>
                <div className="border"> 
                  <input
                    type='password'
                    className="outline-none bg-transparent p-2 "
                    placeholder='Enter password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
               
              </div>

              <div className=" w-full mt-2">
                <label className=" px-2 py-1 ">Confirm Password</label>
                <div className="border">
                  <input
                    type='password'
                    className="outline-none bg-transparent p-2 "
                    placeholder='Confirm password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
               
              </div>
              <div className="mt-2 w-full">
                <button type='submit' className="bg-primary text-white p-2 w-full">
                  Update
                </button>
              </div>
            
            </form>
          )}
        </div>
        <div className="w-2/3 px-2">
          <h2 className="p-2 bg-gray-200">My Orders</h2>

          {loadingOrders ? (
            <Loader />
          ) : errorOrders ? (
            <Message variant='danger'>{errorOrders}</Message>
          ) : (
            <table className="table w-full">
              <thead>
                <tr className="text-left">
                  <th>ID</th>
                  <th>DATE</th>
                  <th>TOTAL</th>
                  <th>PAID</th>
                  {/* <th>Delivred</th> */}

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>{order.totalPrice}</td>
                    <td>
                      {order.isPaid ? (
                        order.paidAt.substring(0, 10)
                      ) : (
                        <span>No</span>
                      )}
                    </td>
                    {/* <td>
                      {order.isDelivered ? (
                        order.deliveredAt.substring(0, 10)
                      ) : (
                        <span>No</span>
                      )}
                    </td> */}
                    <td>
                      <Link to={`/order/${order._id}`}>
                        <button>
                          Details
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfileScreen
