import React from 'react'
import { Link } from 'react-router-dom'
import SideBar from '../../components/SideBar'

function Dashboard() {
  return (
    <div className="admin-screen">
        <div className="flex ">
            <div className='w-1/5 '>
                <SideBar />
            </div>
            
            
            <div className="px-2 md:w-3/5 w-full">
                <h1 className='text-xl pb-1 '>Dashboard </h1>
                <hr />
                <div className='pt-3 grid md:grid-cols-4  text-center'>
                    <div className='p-4 border-l border-y '>
                        <div className=''>
                            Paid Orders
                        </div>
                        <div className='text-2xl font-bold'>
                            3
                        </div>

                        
                    </div>
                    <div className='p-4 border-l border-y '>
                        <div className=''>
                            Not Paid
                        </div>
                        <div className='text-2xl font-bold'>
                            5
                        </div>

                    </div>
                    <div className='p-4 border-l border-y '>
                        <div className=''>
                            Products
                        </div>
                        <div className='text-2xl font-bold'>
                            20
                        </div>
                    </div>
                    <div className='p-4 border-x border-y '>

                        <div className=''>
                           Vistes
                        </div>
                        <div className='text-2xl font-bold'>
                            430
                        </div>

                    </div>
                </div>
                 
            </div>
        </div>
    </div>
  )
}

export default Dashboard