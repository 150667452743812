import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { deleteOrder, listOrders } from '../../actions/orderActions'
import SideBar from '../../components/SideBar'
import { Link, useHistory } from 'react-router-dom'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import getFormatedPrice from '../../utils/getFormatedPrice'
import Paginate from '../../components/Paginate'


const OrderItem = ({order}) => {
  const ref = useRef(null)

  const history = useHistory()

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref,()=> setIsOpen(false))

 
  const deleteHandler = (id) => (ev) => {
    dispatch(deleteOrder(id))
  }

 

  return (
    <div  className='flex justify-between mb-2 w-full py-2 px-4 border rounded-sm'>
     <div className=''>
        {/* <div className='w-20 float-left'>
          <img src={product?.images[0]?.image} alt="" />
        </div> */}
        <div className='pl-2 float-right'>
          <div className='text-xl pb-2'>
            {order.shippingAddress && order.shippingAddress.lastName } {order.shippingAddress && order.shippingAddress.firstName }
            
          </div>
          <div className='pb-1'>
              Total : {getFormatedPrice(order.totalPrice)}
          </div>
          <div className='flex justify-between flex-grow'>
            <div className='opacity-70 pr-4'>
              Order at :{order?.createdAt?.substring(0, 10)  }
            </div>
            <div className='opacity-70'>
              {order.isPaid? 
                <div className='text-green-600'>
                  |  Paid at { order.isPaid && order.paidAt.substring(0, 10)}
                </div>:
                <div className='text-red-600'>
                  |  Not Paid 
                </div>
                } 
            </div>
           
          </div>
        
          
        </div>
     </div>

      <div className='cursor-pointer relative'>
        <svg xmlns="http://www.w3.org/2000/svg" onClick={()=> setIsOpen(!isOpen)  } className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>

        <div ref={ref} className={`p-2 ${isOpen ? 'block':'hidden'} absolute bg-white shadow-md border z-50 right-0`}>
          <button onClick={()=> history.push(`/order/${order._id}/`)} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4'>
            Order Details
          </button>

          <a href={`mailto:${order.shippingAddress.email}`} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4'>
            Send Email
          </a>
          

          <hr />
          <button onClick={deleteHandler(order._id)} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4'>
            Delete Order
          </button>
        </div>
      </div>
     
      
    </div>
  )
}

const OrderListScreen = ({ history,match }) => {
  const dispatch = useDispatch()

  const pageNumber = match.params.pageNumber || 1

  const orderList = useSelector((state) => state.orderList)
  const { loading,pages,page, error, orders } = orderList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const order_choices = [
    {id:1,name:"All",value:"All"},
    {id:2,name:"Paid",value:"True"},
    {id:3,name:"Not Paid",value:"False"},

  ]

  const [orderStatus,setOrderStatus] = useState()

  useEffect(() => {
    
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(orderStatus,pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history,pageNumber, userInfo,orderStatus])
  
  return (
    <>
      <div className="admin-screen">
          <div className="flex">
            <div className='w-1/5'>
              <SideBar />
            </div>
            
            
            <div className="px-2 md:w-3/5 w-full">
              <div className='flex  justify-between px-2   items-center align-items-center'>
                <h1 className='text-xl pb-1 '>Orders </h1>
                <hr />
              
              </div>
              {loading ? (
                <Loader />
                ) : error ? (
                  <Message variant='danger'>{error}</Message>
                ) : (
                  <div  className='w-full'>
                  
       
                      {orders.map((order) => <OrderItem order={order} key={order._id} /> )}
                      
                      <div className='pt-3'>
                        <Paginate type={"orderlist"} pages={pages} page={page} isAdmin={true} />
                      </div>

                  </div>
                    
                )}

            </div>

            <div className='w-1/5 pl-4 px-2'>
                <div className='py-2'>
                  <h1 className='text-xl border-b '>Filters</h1>
                </div>

                <div className='pt-2'>
                  {order_choices.map(or_ch =>  <div key={or_ch.id} className='py-1'>
                     <input type="radio" onChange={(e)=> setOrderStatus(e.target.value)} name="status" id="status" value={or_ch.value} /> {or_ch.name}
                  </div>)}
                
              
                 
                </div>
            </div>
          </div>
        </div>
       
    </>
  )
}

export default OrderListScreen
