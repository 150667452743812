import React from 'react'

function Cgv() {
  return (
    <div className='pt-32 p-4 max-w-screen-md mx-auto'>
        <h1 className='text-xl py-4'>
            Booking Procedure
        </h1>
        <ul>
            <li>
                Choose your suitable package with number of tents and click “Add to cart”.
            </li>
            <li>
                Continue Shopping or click on “proceed to checkout”
            </li>
            <li>
                Fill in the guests’ information.
            </li>
            <li>
                Proceed to payment.
            </li>
            <li>
                You will be directed to a secured payment platform “Visa Verified”.
            </li>
            <li>
                Fill in your card details and click on Pay.
            </li>
            <li>
                Once payment confirmed, click on “Go back to merchant site”.
            </li>
            <li>
                You will receive a confirmation e-mail with your booking details.
            </li>
            <li>
                SEE YOU IN DAKHLA!
            </li>
        </ul>

        <h1 className='text-xl py-4'>
            Booking Conditions
        </h1>
        <ul>
            <li>
                To Confirm your booking, a 100% deposit of your total stay is required.
            </li>
         
            <li>
             All changes are allowed up to one month prior to your arrival.
            </li>
            <li>
             Name changes are allowed up to 7 days before your arrival.
            </li>
            <li>
                Once check-in done, no change of hotels will be allowed on site.
            </li>
            <li>
                All extra charges will have to be paid directly to your accommodation.
            </li>
            <li>
                Only married couples can share a room.
            </li>
            <li>
                No smoking is allowed in tents.
            </li>
            <li>
                A strict hotel policy will be observed.
            </li>
            <li>
                Tolerance zero.
            </li>
        </ul>

    </div>
  )
}

export default Cgv